* {
    font-family: 'Rubik', sans-serif;
}

html,
body {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    height: 100%;
    background: #ffe300;
    font-family: 'helvetica', sans-serif;
}

@mixin pureCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin containerSpecs {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}

$footerSize: 80px;

.container {
    @include containerSpecs;

    .header {
        height: 8%;
        @include pureCenter;

        img {
            height: 25px;
            margin: 0 auto;
        }
    }
    .titles {
        height: 25%;
        max-width: 95%;
    }

    .spinner-container {
        height: calc(55% - $footerSize);
    }
    .grisinn {
        height: 12%;
    }
    .footer {
        height: $footerSize;
    }

    .spinner-container {
        aspect-ratio: 1 / 1;
        background-size: 80% 80%;
        margin: 20px 0;
        max-width: 100%;

        background-image: url('./assets/comma.svg');
        background-position: center;
        background-repeat: no-repeat;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .spinner {
            &:focus {
                outline: none;
            }
        }
    }
}

.selected-backdrop {
    backdrop-filter: blur(10px);
    background-color: rgba(#ff59b0, 0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    animation: 0.2s ease-out 0s 1 popIn;

    .selected-container {
        @include containerSpecs;

        .titles {
            height: 25%;
            max-width: 80%;
        }
        .result-image {
            height: calc(48% - $footerSize);
        }
        .results {
            height: 15%;
            color: white;
            text-align: center;
            h1 {
                margin: 0;

                font-size: 4.2vh;
            }
            p {
                text-align: center;
                margin: 0 auto;
                font-size: 2vh;
            }
        }
        .grisinn {
            height: 12%;
        }
        .footer {
            height: $footerSize;
        }

        .result-image {
            aspect-ratio: 1 / 1;
            background-size: 90% 90%;
            margin: 20px 0;
            max-width: 100%;

            background-image: url('./assets/comma_black.svg');
            background-position: center;
            background-repeat: no-repeat;

            @include pureCenter;

            img {
                object-fit: contain;
                height: 90%;
                width: 90%;
                transform: rotate(6deg);
            }
        }
    }
}

@keyframes popIn {
    0% {
        opacity: 0;
        background-color: rgba(#ff59b0, 0);
        backdrop-filter: blur(0px);
    }
    30% {
        backdrop-filter: blur(10px);
    }
    100% {
        opacity: 1;
        background-color: rgba(#ff59b0, 0.9);
    }
}

.titles {
    @include pureCenter;

    text-align: center;
    p {
        width: 100%;
        margin: 0;
        font-weight: 400;
        font-size: 2vh;
    }
    h1 {
        width: 100%;
        margin: 10px 0 0 0;
        font-size: 7.5vh;
        line-height: 0.9;
        font-weight: 700;
    }
}

.footer {
    cursor: pointer;
    background: black;
    color: white;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    z-index: 10;
    &:hover {
        font-size: 27px;
        color: #ff59b0;
    }
    transition: all 0.1s ease-in-out;

    display: flex;
    justify-content: center;
    flex-direction: column;

    * {
        margin: 0;
    }
    p {
        font-size: 14px;
    }
}

.grisinn {
    // @include pureCenter;
    // flex-direction: column-reverse;
    position: relative;
    width: 100%;

    img {
        position: absolute;

        // height: 100%;
        min-height: 160px;
        bottom: -30px;
        right: -80px;
        // margin-right: -100%;
        // margin-bottom: 70px;
        // transform: rotate(12deg);
    }
}
